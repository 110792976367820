<template>
<!--  min-height: 100vh高度100等分-->
    <el-container style="min-height: 100vh;">
      <el-aside :width="sideWidth +'px'" style="background-color: rgb(238, 241, 246);">
        <!--      引用侧边栏组件并传值-->
        <Aside :isCollapse="isCollapse" :logoTextShow="logoTextShow" />
      </el-aside>

      <el-container>
        <el-header style="border-bottom: 1px solid #ccc;">
<!--          引用header组件-->
          <Header :collapseBtnClass="collapseBtnClass" :collapse="collapse" :admin="admin" />
        </el-header>

        <el-main>
<!--          当前页面的子路由会在<router-view/>里面展示-->
          <router-view @refreshAdmin="getAdmin"/>
        </el-main>

      </el-container>
    </el-container>
</template>

<script>
//导入侧边栏组件
import Aside from "@/components/Aside";
import Header from "@/components/Header";

export default {

  data(){
    return{
      collapseBtnClass:'el-icon-s-fold',
      isCollapse:false,
      sideWidth:200,
      logoTextShow:true,
      admin:localStorage.getItem("admin")?JSON.parse(localStorage.getItem("admin")):{}
    }
  },

  components: {
    Aside,
    Header
  },
  methods:{
    collapse(){
      this.isCollapse = !this.isCollapse
      if(this.isCollapse){//收缩
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
        this.logoTextShow = false
      }else {//展开
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
        this.logoTextShow = true
      }
    },
    getAdmin(){
      //从后台获取用户数据
      this.request.get("/admin/username/"+this.admin.username).then(res=>{
        this.admin = res.data
      })
    },

  }
}
</script>

