import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex)
//1.下载vuex 2.配store/index.js 3.在router/index.js导入import store from "@/store"并引入路由守卫 4.更改导航栏的index为路由的路径
const store = new Vuex.Store({
    state:{
        currentPathName:''
    },
    mutations:{
        setPath(state){
            state.currentPathName = localStorage.getItem("currentPathName")
        }
    }
})

export default store