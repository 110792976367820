<template>
  <div style=" line-height: 60px;display: flex" >
    <div style="flex: 1">
<!--     折叠-->
      <span :class="collapseBtnClass" style="cursor: pointer; font-size: 18px" @click="collapse"></span>
<!--页签-->
      <el-breadcrumb separator="/" style="display: inline-block; margin-left: 10px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item >{{currentPathName}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-dropdown style="width: 105px; cursor: pointer">
      <div style="display: inline-block" >
        <img :src="admin.img" alt="" style="width: 30px; border-radius: 50%; position: relative;top: 10px;right: 5px">
      <span>{{admin.username}}</span><i class="el-icon-arrow-down" style="margin-left: 10px"></i>
      </div>
      <el-dropdown-menu slot="dropdown" style="width: 100px;text-align: center ">
        <el-dropdown-item style="font-size: 14px;padding: 5px 0">
          <span style="text-decoration: none" @click="logout">退出</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "Header",
  props:{
    collapseBtnClass: String,
    collapse: '',
    admin:Object
  },
  computed:{
    currentPathName(){
      return this.$store.state.currentPathName;//需要监听的数据
    }
  },
  data(){
    return{

    }
  },
  watch:{
    currentPathName(newVal,oldVal){
    }
  },
  methods:{
    logout(){
      this.$router.push("/login")
      localStorage.removeItem("admin")
      this.$message.success("退出成功")
    },
  },
}
</script>

<style scoped>

</style>