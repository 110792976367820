import Vue from 'vue'
import VueRouter from 'vue-router'
import ManageView from '../views/Manage.vue'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manage',
    component: ManageView,
    redirect:"/home",
    children:[
      {path: 'home', name: '首页', component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')},
      {path: 'user', name: '注册用户', component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')},
      {path: 'admin', name: '管理员', component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')},
      {path: 'carousel', name: '轮播图', component: () => import(/* webpackChunkName: "about" */ '../views/Carousel.vue')},
      {path: 'order', name: '订单信息', component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue')},
      {path: 'milktea', name: '奶茶信息', component: () => import(/* webpackChunkName: "about" */ '../views/Milktea.vue')},
      {path: 'notice', name: '公告信息', component: () => import(/* webpackChunkName: "about" */ '../views/Notice.vue')},
      {path: 'member', name: '会员信息', component: () => import(/* webpackChunkName: "about" */ '../views/Member.vue')},
      {path: 'report', name: '报表', component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue')},
      {path: 'problem', name: '问题反馈', component: () => import(/* webpackChunkName: "about" */ '../views/Problem.vue')},
      {path: 'infoclas', name: '资讯分类', component: () => import(/* webpackChunkName: "about" */ '../views/Infoclas.vue')},
      {path: 'info', name: '资讯', component: () => import(/* webpackChunkName: "about" */ '../views/Info.vue')},
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/Login.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//路由守卫1.下载vuex 2.配store/index.js 3.在router/index.js导入import store from "@/store"并引入路由守卫
router.beforeEach((to,from,next) => {
  localStorage.setItem("currentPathName",to.name)//配置当前的路由名称,为了在header组件中去使用
  store.commit("setPath")//出发store的数据更新
  next()  //放行路由
})


export default router
