<template>
  <el-menu :default-openeds="['1', '3']" style="min-height: 100%;overflow-x: hidden"
           background-color="rgb(48,65,86)"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :collapse="isCollapse"
           router
  >
    <div style="height: 60px;line-height: 60px;text-align: center">
      <img src="../assets/logo.png" alt="" style="width: 30px;position: relative;top:7px;margin-right: 5px">
      <b style="color: white" v-show="logoTextShow">喜阁里茶</b>
    </div>
      <el-menu-item  index="/">
        <template slot="title"><i class="el-icon-s-home"></i>
          <span slot="title">主页</span>
        </template>
      </el-menu-item>
    <el-submenu index="1">
      <template slot="title"><i class="el-icon-user"></i>
        <span slot="title">用户管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/user">注册用户</el-menu-item>
        <el-menu-item index="/admin">管理员</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="2">
      <template slot="title"><i class="el-icon-document"></i>
        <span slot="title">公共管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/carousel">轮播图</el-menu-item>
        <el-menu-item index="/notice">奶茶店公告</el-menu-item>
        <el-menu-item index="/info">奶茶店资讯</el-menu-item>
        <el-menu-item index="/infoclas">资讯分类</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="3">
      <template slot="title"><i class="el-icon-s-order"></i>
        <span slot="title">订单管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/order">订单信息</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="4">
      <template slot="title"><i class="el-icon-milk-tea"></i>
        <span slot="title">奶茶管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/milktea">奶茶信息</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title"><i class="el-icon-s-custom"></i>
        <span slot="title">会员管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/member">会员信息</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="6">
      <template slot="title"><i class="el-icon-question"></i>
        <span slot="title">问题反馈管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/problem">问题反馈</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="7">
      <template slot="title"><i class="el-icon-data-line"></i>
        <span slot="title">报表管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/report">报表</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "Aside",
  props:{
    isCollapse: Boolean,
    logoTextShow: Boolean
  }

}
</script>

<style scoped>

</style>